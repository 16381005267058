import React, { useEffect, useState } from 'react';
import Topbar from '../components/Topbar';
import Leftbar from '../components/Leftbar';
import { Link } from 'react-router-dom';
import { getPhyios, displayError, displaySuccess, exportPhysio, getUnverifiedPhyios, getUserDetail, getPhysioDetail } from '../Utils/utils';
import { toggleLoader } from '../actions/loader';
import { connect } from 'react-redux';
import axios from 'axios';
import config from '../config/config';
import ReactPaginate from 'react-paginate';
import { Col, Row } from 'react-bootstrap';
import moment from 'moment';

const PhysioDetail = (props) => {
  const [users, setUsers] = useState('');
  const [limit, setLimit] = useState(10);
  const [skip, setSkip] = useState(0);
  const [search, setSearch] = useState('');
  const [pageCount, setPageCount] = useState(1);
  const [total, setTotal] = useState('');

  const getList = async (search) => {
    props.toggleLoader(true);
    var getUsers_var = await getPhysioDetail(props.match.params.id);
    setUsers(getUsers_var?.data)
    console.log(getUsers_var?.data, "====ppp")
    props.toggleLoader(false);
  }

  useEffect(() => {
    getList()
  }, [props])


  return (
    <>
      <body class="g-sidenav-show  bg-gray-100">
        <div id="wrapper">
          <Leftbar />
          <main className="main-content border-radius-lg">
            <Topbar />

            <div className="header bg-primary pb-6">
              <div className="container-fluid">
                <div className="header-body">
                  <div className="row align-items-center py-4">
                    <div className="col-lg-6 col-7">
                      <nav aria-label="breadcrumb" className="d-none d-md-inline-block ml-md-4">
                        <ol className="breadcrumb breadcrumb-links breadcrumb-dark">
                          <li className="breadcrumb-item"><Link to="/dashboard"><i className="fas fa-home" /></Link></li>
                          <li className="breadcrumb-item"><a href="#">Service Providers</a></li>
                        </ol>
                      </nav>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="container-fluid mt--6">
              <div className="row">
                <div className="col">
                  <div className="card">
                    <div className="card-header border-0">
                      <div className="row">
                        <div className="col-6">
                          <h3 className="mb-0">{users?.role == 'user' ? 'User' : null}</h3>
                        </div>

                      </div>
                    </div>
                    <div className="card-body">
                      {/* <div className="row"> */}
                      <div className="col-md-9">
                        <div className="doc_type">{users.first_name} {users.last_name}</div>
                        {
                          users?.email &&
                          <div className='row'>
                            <div className='col-3'>
                              <label style={{ fontSize: 14, marginRight: 20, marginLeft: 0 }}>Email :</label>
                            </div>
                            <div className='col-8'>
                              <label style={{ fontSize: 14, marginRight: 20, marginLeft: 0 }}>{users.email}</label>
                            </div>
                          </div>
                        }

                        {users.phone_number &&
                          <div className='row'>
                            <div className='col-3'>
                              <label style={{ fontSize: 14, marginRight: 20, marginLeft: 0 }}>Phone Number :</label>
                            </div>
                            <div className='col-8'>
                              <label style={{ fontSize: 14, marginRight: 20, marginLeft: 0 }}>{users.phone_number}</label>
                            </div>
                          </div>}
                        {/* <div className='row'>
                          <div className='col-3'>
                            <label style={{ fontSize: 14, marginRight: 20, marginLeft: 0 }}>DOB :</label>
                          </div>
                          <div className='col-8'>
                            <label style={{ fontSize: 14, marginRight: 20, marginLeft: 0 }}>{users.dob}</label>
                          </div>
                        </div> */}
                        {users.gender &&
                          <div className='row'>
                            <div className='col-3'>
                              <label style={{ fontSize: 14, marginRight: 20, marginLeft: 0 }}>Gender :</label>
                            </div>
                            <div className='col-8'>
                              <label style={{ fontSize: 14, marginRight: 20, marginLeft: 0 }}>{users.gender}</label>
                            </div>
                          </div>}
                        {users?.role == 'physio' &&
                          <div className='row'>
                            <div className='col-3'>
                              <label style={{ fontSize: 14, marginRight: 20, marginLeft: 0 }}>KYC Status :</label>
                            </div>
                            <div className='col-8'>
                              <label style={{ fontSize: 14, marginRight: 20, marginLeft: 0 }}>{users.kyc_status}</label>
                            </div>
                          </div>
                        }
                        {users.address &&
                          <div className='row'>
                            <div className='col-3'>
                              <label style={{ fontSize: 14, marginRight: 20, marginLeft: 0 }}>Address :</label>
                            </div>
                            <div className='col-8'>
                              <label style={{ fontSize: 14, marginRight: 20, marginLeft: 0 }}>{users.address}</label>
                            </div>
                          </div>}
                        {users?.role == 'physio' &&

                          <div className='row'>
                            <div className='col-3'>
                              <label style={{ fontSize: 14, marginRight: 20, marginLeft: 0 }}>Designation :</label>
                            </div>
                            <div className='col-8'>
                              <label style={{ fontSize: 14, marginRight: 20, marginLeft: 0 }}>{users.designation}</label>
                            </div>
                          </div>
                        }
                        {users?.role == 'physio' &&

                          <div className='row'>
                            <div className='col-3'>
                              <label style={{ fontSize: 14, marginRight: 20, marginLeft: 0 }}>Experience :</label>
                            </div>
                            <div className='col-8'>
                              <label style={{ fontSize: 14, marginRight: 20, marginLeft: 0 }}>{users.experience}</label>
                            </div>
                          </div>
                        }

                        {users.description &&
                          <div className='row'>
                            <div className='col-3'>
                              <label style={{ fontSize: 14, marginRight: 20, marginLeft: 0 }}>Description :</label>
                            </div>
                            <div className='col-8'>
                              <label style={{ fontSize: 14, marginRight: 20, marginLeft: 0 }}>{users.description}</label>
                            </div>
                          </div>}
                        {users.educational_journey &&
                          <div className='row'>
                            <div className='col-3'>
                              <label style={{ fontSize: 14, marginRight: 20, marginLeft: 0 }}>Educational Journey :</label>
                            </div>
                            <div className='col-8'>
                              <label style={{ fontSize: 14, marginRight: 20, marginLeft: 0 }}>{users.educational_journey}</label>
                            </div>
                          </div>}
                        {users?.doctor_interest != null &&
                          < div className='row'>
                            <div className='col-3'>
                              <label style={{ fontSize: 14, marginRight: 20, marginLeft: 0 }}>Interests :</label>
                            </div>
                            <div className='col-8'>
                              {/* {users?.doctor_interest && users?.doctor_interest.length > 0 &&
                              <label style={{ fontSize: 14, marginRight: 20, marginLeft: 0 }}>{users?.doctor_interest.map(x => x?.name).join(', ')}</label>
                              } */}
                              {
                                users?.doctor_interest &&
                                <label style={{ fontSize: 14, marginRight: 20, marginLeft: 0 }}>{Array.isArray(users?.doctor_interest) ? users.doctor_interest.map(x => x?.name).join(', ') : users?.doctor_interest.name}</label>
                              }

                            </div>
                          </div>
                        }
                        {users?.doctor_specialties && users?.doctor_specialties.length > 0 &&
                          <div className='row'>
                            <div className='col-3'>
                              <label style={{ fontSize: 14, marginRight: 20, marginLeft: 0 }}>Specialties :</label>
                            </div>
                            <div className='col-8'>
                              {users?.doctor_specialties && users?.doctor_specialties.length > 0 &&
                                <label style={{ fontSize: 14, marginRight: 20, marginLeft: 0 }}>{users?.doctor_specialties.map(x => x.name).join(', ')}</label>}
                            </div>
                          </div>
                        }
                        <div>

                        </div>
                      </div>
                    </div>

                  </div>
                </div>
              </div>
            </div>

          </main>
        </div >
      </body >
    </>
  )
}
const mapDispatchToProps = {
  toggleLoader
}
const mapStateToProps = (state) => {
  return {
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(PhysioDetail);
