import React, { useEffect, useState } from 'react';
import Topbar from '../components/Topbar';
import Leftbar from '../components/Leftbar';
import { Link } from 'react-router-dom';
import { displayError, displaySuccess, getInterestById } from '../Utils/utils';
import axios from 'axios';
import config from '../config/config';
import { connect } from 'react-redux';
import { toggleLoader } from '../actions/loader';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from 'moment';

const EditInterest = (props) => {
  const [name, setName] = useState('');
  const [type, setType] = useState('');
  const [imageUrl, setImageUrl] = useState('');
  const [iconUrl, setIconUrl] = useState('');

  const getInterest = async () => {
    props.toggleLoader(true)
    const data = await getInterestById(props.match.params.id);
    setName(data.data?.name)
    setType(data.data?.type)
    setImageUrl(data?.data?.image)
    setIconUrl(data?.data?.icon)
    props.toggleLoader(false)
  }

  useEffect(() => {
    getInterest()
  }, [])
 
  const addInterest = async () => {
    props.toggleLoader(true);
    let path = config.siteUrl;
    path = path + '/update-interest/' + props?.match?.params?.id;
    var token = localStorage.getItem('token');

    const data = {
      'name': name,
      'type': type,
      'image': imageUrl,
      'icon': iconUrl,
    }
    axios.post(path, data, {
      headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` }
    })
      .then(function (response) {
        props.toggleLoader(false);
        displaySuccess(response?.data?.message);
        setTimeout(() => window.location.href = "/interests", 3000)
      })
      .catch(function (error) {
        props.toggleLoader(false);
        displayError(error);
      })
  }

  const uploadImage = async (e, type) => {
    props.toggleLoader(true);
    if (!e.target.files[0]) {
      displayError('Please select a file')
    }
    else {
      let path = config.siteUrl;
      path = path + '/image-upload';
      var token = localStorage.getItem('token');

      const data = new FormData();
      data.append('file', e.target.files[0]);
      axios.post(path, data, {
        headers: { 'Content-Type': 'multipart/form-data', 'Accept': 'multipart/form-data', Authorization: `Bearer ${token}` }
      })
        .then(function (response) {
          props.toggleLoader(false);
          // displaySuccess(response?.data?.message);
          if (type == 'icon') {
            setIconUrl(response?.data?.data)
          }
          else {
            setImageUrl(response?.data?.data)
          }
        })
        .catch(function (error) {
          props.toggleLoader(false);
          displayError(error);
        })
    }
  }


  return (
    <>
      <body class="g-sidenav-show  bg-gray-100">
        <div id="wrapper">
          <Leftbar />
          <main className="main-content border-radius-lg">
            <Topbar />
            <div>
              <div className="header bg-primary pb-6">
                <div className="container-fluid">
                  <div className="header-body">
                    <div className="row align-items-center py-4">
                      <div className="col-lg-6 col-7">
                        <nav aria-label="breadcrumb" className="d-none d-md-inline-block ml-md-4">
                          <ol className="breadcrumb breadcrumb-links breadcrumb-dark">
                            <li className="breadcrumb-item"><Link to="/dashboard"><i className="fas fa-home" /></Link ></li>
                            <li className="breadcrumb-item"><Link to="/interests">Interest </Link ></li>
                            <li className="breadcrumb-item active" aria-current="page">Edit Interest</li>
                          </ol>
                        </nav>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="container-fluid mt--6">
                <div className="row">
                  <div className="col">
                    <div className="card mb-4">
                      <div className="card-header">
                        <h3 className="mb-0">Edit Interest</h3>
                      </div>
                      <div className="card-body">
                        <div className="row">
                          <div className="col-md-4">
                            <div className="form-group">
                              <label className="form-control-label" >Name</label>
                              <input type="text" className="form-control" placeholder="Name"
                                onChange={e => setName(e.target.value)} value={name}
                              />
                            </div>
                          </div>
                          <div className="col-md-4">
                            <div className="form-group">
                              <label className="form-control-label">Type</label>
                              <input type="text" className="form-control" placeholder="Type"
                                onChange={e => setType(e.target.value)} value={type}
                              />
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="form-group">
                              <label className="form-control-label" >Image</label>
                              <input type="file" accept=".jpg,.jpeg,.png" className="form-control"
                                onChange={e => { uploadImage(e, 'image') }} />
                            </div>
                          </div>
                          <div className="col-md-4">
                            <div className="doc_type">Uploaded Image</div>
                            <img style={{ width: 'auto', height: 200 }} src={imageUrl} />
                          </div>
                          <div className="col-md-6">
                            <div className="form-group">
                              <label className="form-control-label" >Icon</label>
                              <input type="file" accept=".jpg,.jpeg,.png" className="form-control"
                                onChange={e => { uploadImage(e, 'icon') }} />
                            </div>
                          </div>
                          <div className="col-md-4">
                            <div className="doc_type">Uploaded Icon</div>
                            <img style={{ width: 'auto', height: 200 }} src={iconUrl} />
                          </div>
                        </div>
                        <button className="btn btn-primary"
                          onClick={addInterest}
                        > <i className="far fa-paper-plane" /> Submit </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </main>
        </div>
      </body>
    </>
  );
}
const mapDispatchToProps = {
  toggleLoader
}
const mapStateToProps = (state) => {
  return {
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(EditInterest);



