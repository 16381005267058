import React, { useEffect, useState } from 'react';
import Topbar from '../components/Topbar';
import Leftbar from '../components/Leftbar';
import { Link } from 'react-router-dom';
import { getPhyioKyc, displayError, displaySuccess } from '../Utils/utils';
import axios from 'axios';
import config from '../config/config';
import { connect } from 'react-redux';
import { toggleLoader } from '../actions/loader';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from 'moment';

const AddCoupon = (props) => {
  const [kyc, setKyc] = useState({});
  const [show, setShow] = useState(false);
  const [comment, setComment] = useState('');
  const [discount, setDiscount] = useState('');
  const [maxDisc, setMaxDisc] = useState('');
  const [quantity, setQuantity] = useState('');
  const [code, setCode] = useState('');
  const [desc, setDesc] = useState('');
  const [isPublic, setIsPublic] = useState('');
  const [expiry, setExpiry] = useState(new Date());
  const [discountType, setDiscountType] = useState('percent');


  const addCoupon = async () => {
    props.toggleLoader(true);

    let path = config.siteUrl;
    path = path + '/create-coupon';
    var token = localStorage.getItem('token');
    var data = {};

    data = {
      code: code,
      description: desc,
      discount: discount,
      maximum_discount: maxDisc,
      quantity: quantity,
      is_public: isPublic == "Yes" ? true : false,
      expiry_date: moment(expiry).format('YYYY-MM-DD'),
      discount_type: discountType
    }
    axios.post(path, data, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      }
    }
    )
      .then(function (response) {
        props.toggleLoader(false);
        displaySuccess(response?.data?.message);
        setTimeout(() =>
          window.location.href = "/coupons"
          , 3000)

      })
      .catch(function (error) {
        props.toggleLoader(false);
        displayError(error);
      })
  }

  return (
    <>
      <body class="g-sidenav-show  bg-gray-100">
        <div id="wrapper">
          <Leftbar />
          <main className="main-content border-radius-lg">
            <Topbar />
            <div>
              <div className="header bg-primary pb-6">
                <div className="container-fluid">
                  <div className="header-body">
                    <div className="row align-items-center py-4">
                      <div className="col-lg-6 col-7">
                        <nav aria-label="breadcrumb" className="d-none d-md-inline-block ml-md-4">
                          <ol className="breadcrumb breadcrumb-links breadcrumb-dark">
                            <li className="breadcrumb-item"><Link to="/dashboard"><i className="fas fa-home" /></Link ></li>
                            <li className="breadcrumb-item"><Link to="/physios">Coupon </Link ></li>
                            <li className="breadcrumb-item active" aria-current="page">Add Coupon</li>
                          </ol>
                        </nav>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="container-fluid mt--6">
                <div className="row">
                  <div className="col">
                    <div className="card mb-4">
                      <div className="card-header">
                        <h3 className="mb-0">Add Coupon</h3>
                      </div>
                      <div className="card-body">
                        <div className="row">
                          <div className="col-md-4">
                            <div className="form-group">
                              <label className="form-control-label" >Code</label>
                              <input type="text" className="form-control" placeholder="Code"
                                onChange={e => setCode(e.target.value)} value={code}
                              />
                            </div>
                          </div>
                          <div className="col-md-4">
                            <div className="form-group">
                              <label className="form-control-label">Description</label>
                              <input type="text" className="form-control" placeholder="Description"
                                onChange={e => setDesc(e.target.value)} value={desc}
                              />
                            </div>
                          </div>
                          <div className="col-md-4">
                            <div className="form-group">
                              <label className="form-control-label" >Discount Type</label>
                              <select className="form-control"
                                onChange={e => {
                                  if (e.target.value == 'amount') {
                                    setMaxDisc('')
                                  }
                                  setDiscountType(e.target.value)
                                }}
                                value={discountType}
                              >
                                <option value="percent">Percentage</option>
                                <option value="amount">Discount Value</option>
                              </select>
                            </div>
                          </div>
                          <div className="col-md-4">
                            <div className="form-group">
                              <label className="form-control-label" >Discount </label>
                              <input type="text" className="form-control" placeholder="Discount"
                                value={discount}
                                onChange={(e) => {
                                  const value = e.target.value;
                                  const regex = /^[0-9]+$/;
                                  if (value.match(regex) || value === "") {
                                    setDiscount(value)
                                  }
                                }}
                              />
                            </div>
                          </div>
                          <div className="col-md-4">
                            <div className="form-group">
                              <label className="form-control-label" >Maximum Discount </label>
                              <input type="text" className="form-control" placeholder="Maximum Discount"
                                value={maxDisc}
                                disabled={discountType !== "percent" ? true : false}
                                onChange={(e) => {
                                  const value = e.target.value;
                                  const regex = /^[0-9]+$/;
                                  if (value.match(regex) || value === "") {
                                    setMaxDisc(value)
                                  }
                                }}
                              />
                            </div>
                          </div>
                          <div className="col-md-4">
                            <div className="form-group">
                              <label className="form-control-label" >Quantity </label>
                              <input type="text" className="form-control" placeholder="Quantity"
                                value={quantity}
                                onChange={(e) => {
                                  const value = e.target.value;
                                  const regex = /^[0-9]+$/;
                                  if (value.match(regex) || value === "") {
                                    setQuantity(value)
                                  }
                                }}
                              />
                            </div>
                          </div>
                          <div className="col-md-4">
                            <div className="form-group">
                              <label className="form-control-label" >Is Public </label>
                              <select className="form-control" id="validationCustom04" required=""
                                onChange={e => setIsPublic(e.target.value)}
                              >
                                <option value="Yes">Yes</option>
                                <option selected Value="No">No</option>
                              </select>
                            </div>
                          </div>
                          <div className="col-md-4">
                            <div className="form-group mb-0">
                              <label className="form-control-label" >Expiry Date</label>
                            </div>
                            <div className='date-pickk'>
                              <DatePicker
                                className="basic-form basic-form input"
                                selected={expiry}
                                onChange={(date) => setExpiry(date)}
                                minDate={new Date()}
                                // minDate={last30}
                                dateFormat="dd/MM/yyyy"
                              />
                            </div>

                            {/* <select className="form-control" id="validationCustom04" required=""
                                onChange={e => setGender(e.target.value)}>
                                <option value="f">f</option>
                                <option selected Value="m">m</option>
                              </select> */}
                          </div>
                        </div>
                        <button className="btn btn-primary"
                          onClick={addCoupon}
                        > <i className="far fa-paper-plane" /> Submit </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </main>
        </div>
      </body>
    </>
  );
}
const mapDispatchToProps = {
  toggleLoader
}
const mapStateToProps = (state) => {
  return {
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(AddCoupon);



