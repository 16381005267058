import React, { useEffect, useState } from 'react';
import Topbar from '../components/Topbar';
import Leftbar from '../components/Leftbar';
import { Link } from 'react-router-dom';
import { getPhyios, displayError, displaySuccess, exportPhysio, getVerifiedPhyios, getInterestsDropdown } from '../Utils/utils';
import { toggleLoader } from '../actions/loader';
import { connect } from 'react-redux';
import axios from 'axios';
import config from '../config/config';
import ReactPaginate from 'react-paginate';
import { Col, Row } from 'react-bootstrap';
import moment from 'moment';

const ApprovedPhysios = (props) => {
  const [users, setUsers] = useState([]);
  const [limit, setLimit] = useState(10);
  const [skip, setSkip] = useState(0);
  const [search, setSearch] = useState('');
  const [pageCount, setPageCount] = useState(1);
  const [total, setTotal] = useState('');
  const [banStatus, setBanStatus] = useState('');
  const [role, setRole] = useState('');
  const [sort, setSort] = useState('desc');
  const [roles, setRoles] = useState([]);


  const getList = async (search) => {
    if (search == '') {
      props.toggleLoader(true);
    }
    var getUsers_var = await getVerifiedPhyios(limit, skip, search, banStatus, role, sort);
    var page = getUsers_var.data.total / 10
    setTotal(getUsers_var.data.total)
    setPageCount(Math.ceil(page))
    setUsers(getUsers_var.data.data);
    props.toggleLoader(false);
  }

  const bannPhysio = (id, status) => {
    props.toggleLoader(true);
    let path = config.siteUrl;
    path = path + '/bann-physio/' + id;
    var token = localStorage.getItem('token');
    const data = {
      status: status,
    }
    axios.post(path, data, { headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` } }
    )
      .then(function (response) {
        props.toggleLoader(false);
        displaySuccess(response?.data?.mesaage);
        getList(search)
      })
      .catch(function (error) {
        props.toggleLoader(false);
        displayError(error);
      })
  }


  const deleteUser = (id) => {
    props.toggleLoader(true);
    let path = config.siteUrl;
    path = path + '/delete-user/' + id;
    var token = localStorage.getItem('token');
    axios.get(path, { headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` } }
    )
      .then(function (response) {
        props.toggleLoader(false);
        getList(search)
        displaySuccess(response?.data?.mesaage);

        // setTimeout(() => {
        //   window.location.reload()
        // }, 1500);
      })
      .catch(function (error) {
        props.toggleLoader(false);
        displayError(error);
      })
  }

  useEffect(() => {
    getList(search)
  }, [skip, search, banStatus, sort, role])

  const exportList = async () => {
    var res = await exportPhysio()
    if (res.status == 200) {
      window.location.href = res.data
    }
  }

  const handlePageClick = async (e) => {
    props.toggleLoader(true);
    window.scroll({ top: 0, left: 0, behavior: 'smooth' });
    const selectedPage = e.selected;
    setSkip(selectedPage * limit)
    // getList(search)

    props.toggleLoader(false);
  };

  const getInterest = async () => {
    var temp = []
    var res = await getInterestsDropdown()
    res.data.map((a) => {
      temp.push({
        label: a.name, value: a._id
      })
    })
    setRoles(temp)
  }


  useEffect(() => {
    getInterest()
  }, [])
  return (
    <>
      <body class="g-sidenav-show  bg-gray-100">
        <div id="wrapper">
          <Leftbar />
          <main className="main-content border-radius-lg">
            <Topbar />

            <div className="header bg-primary pb-6">
              <div className="container-fluid">
                <div className="header-body">
                  <div className="row align-items-center py-4">
                    <div className="col-lg-6 col-7">
                      <nav aria-label="breadcrumb" className="d-none d-md-inline-block ml-md-4">
                        <ol className="breadcrumb breadcrumb-links breadcrumb-dark">
                          <li className="breadcrumb-item"><Link to="/dashboard"><i className="fas fa-home" /></Link></li>
                          <li className="breadcrumb-item"><a href="#">Service Providers</a></li>
                        </ol>
                      </nav>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="container-fluid mt--6">
              <div className="row">
                <div className="col">
                  <div className="card">
                    <div className="card-header border-0">
                      <div className="row">
                        <div className="col-6">
                          <h3 className="mb-0">Service Providers Listing ({total})</h3>
                        </div>
                        <div className='col-6 display-end' >
                          <input type="text" className="form-control"
                            style={{ width: '35%', marginRight: 10 }}
                            placeholder="Search"
                            value={search}
                            onChange={(e) => {
                              setSkip(0)
                              setPageCount(1)
                              setSearch(e.target.value)
                              // const value = e.target.value;
                              // const regex = /^[0-9]+$/;
                              // if (value.match(regex) || value === "") {
                              //   setQuantity(value)
                              // }
                            }}
                          />
                          <button className="btn btn-primary "
                            onClick={() =>
                              exportList()
                            }
                          >Export</button>
                        </div>
                      </div>
                      <div className='row' style={{ gap: '10px' }}>

                        <div className="form-group" style={{ width: '15%' }}>
                          <label className="form-control-label" >Status</label>
                          <select className="form-control"
                            onChange={e => setBanStatus(e.target.value)}
                            value={banStatus}
                          >
                            <option value="">All</option>
                            <option value="true">Banned</option>
                            <option value="false">Not Banned</option>
                          </select>
                        </div>
                        <div className="form-group" style={{ width: '15%' }}>
                          <label className="form-control-label" >Role</label>
                          <select className="form-control"
                            onChange={e => setRole(e.target.value)}
                            value={role}
                          >
                            <option value="">All</option>
                            {roles.map((a) => (
                              <option value={a.value}>{a.label}</option>
                            ))}
                          </select>
                        </div>
                        <div className="form-group" style={{ width: '15%' }}>
                          <label className="form-control-label" >Sort</label>
                          <select className="form-control"
                            onChange={e => setSort(e.target.value)}
                            value={sort}
                          >
                            <option value="desc">Date descending</option>
                            <option value="asc">Date ascending</option>
                          </select>
                        </div>

                        <div className="form-group" style={{
                          width: '15%', display: 'flex',
                          justifyContent: 'end'
                        }}>
                          <label className="form-control-label " ></label>

                          <button className="btn btn-secondary pull-right w-100 "
                            style={{
                              height: '40px',
                              marginTop: 'auto'
                            }}
                            onClick={() => {
                              setBanStatus('')
                              setRole('')
                              setSort('desc')
                            }} >Clear</button>
                        </div>
                      </div>
                    </div>
                    <div className="table-responsive">
                      <table className="table align-items-center table-flush">
                        <thead className="thead-light">
                          <tr>
                            <th>Name</th>
                            <th>Email</th>
                            <th>Location</th>
                            <th>Phone Number</th>
                            <th>Registered Date</th>
                            <th>Approved Date</th>
                            <th>KYC Status</th>
                            <th>Status</th>
                            <th>Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {users.length > 0 ? users?.map((user) => (
                            <tr>
                              <td className="table-user">
                                <Link to={"/physio-detail/" + user._id}>
                                  <b className='name'>{user.first_name}  {user.last_name}</b>
                                </Link>
                              </td>

                              <td className="table-user">
                                <b className='name2'>{user.email} </b>
                              </td>
                              <td className="table-user">
                                <span className="text-muted address">{user.address} </span>
                              </td>

                              <td>
                                <span className="text-muted date">{user.phone_number} </span>
                              </td>
                              <td>
                                {user?.createdAt !== undefined ?
                                  <span className="text-muted date">{moment(user.createdAt).format('ddd, DD MMM YYYY')} </span>
                                  :
                                  <span className="text-muted date">{moment(user.created_at).format('ddd, DD MMM YYYY')} </span>}
                              </td>
                              <td>
                                <span className="text-muted date">{moment(user.kyc_verified_on).format('ddd, DD MMM YYYY')} </span>
                              </td>
                              <td className="table-user">
                                <b style={{ textTransform: 'capitalize' }} className='date'>{user.kyc_status} </b>
                              </td>

                              <td className="table-user">
                                <b style={{ textTransform: 'capitalize' }} className='date'>{user.is_banned ? "Banned" : "Not Banned"} </b>
                              </td>
                              <td>
                                <div className='row' style={{ flexWrap: 'nowrap', gap: '5px', alignItems: 'center', paddingRight: '10px', }}>
                                  {user.is_kyc_submitted &&
                                    <Link to={"/view-kyc/" + user._id}>
                                      <button className="btn btn-success pull-right" style={{ width: '100px' }}>View KYC</button>
                                    </Link>
                                  }
                                  {user.is_banned ?
                                    <button className="btn btn-secondary pull-right " onClick={() => bannPhysio(user._id, false)} >Remove Ban</button>
                                    :
                                    <button className="btn btn-secondary pull-right " onClick={() => bannPhysio(user._id, true)} >Ban</button>
                                  }
                                  <Link to={"/bookings?physio_id=" + user._id}>
                                    <button className="btn btn-primary pull-right " style={{ width: '130px' }}>View Bookings</button>
                                  </Link>
                                  <button className="btn btn-danger pull-right" style={{ width: '130px' }} onClick={() => {
                                    deleteUser(user._id);
                                  }}>Delete user</button>
                                </div>
                              </td>

                            </tr>
                          ))
                            :
                            <td colSpan={6}>

                              <div style={{ textAlign: 'center' }}>
                                No Service Providers
                              </div>
                            </td>

                          }
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
              {users.length > 0 &&
                <Row>
                  {/* <Col xs={4}>
                    <div className='mb-1'>
                      <FormattedMessage id="Showing" /> {users?.length} <FormattedMessage id="accountants from page" /> {current_page} <FormattedMessage id="of" /> {pageCount}
                    </div>
                  </Col> */}
                  <Col xs={12}>
                    <div className='paginate-css'>
                      <ReactPaginate
                        previousLabel="<"
                        nextLabel=">"
                        breakLabel={"..."}
                        breakClassName={"break-me"}
                        pageCount={pageCount}
                        marginPagesDisplayed={0}
                        pageRangeDisplayed={3}
                        onPageChange={handlePageClick}
                        containerClassName={"pagination"}
                        // subContainerClassName={"pages pagination"}
                        activeClassName={"active"}
                      />
                    </div>
                  </Col>
                </Row>}

              {/* {users && users.length > 0 &&
                <div style={{ display: 'flex', justifyContent: 'end' }}>
                  {skip != 0 &&
                    <button className="btn btn-primary mr-4" onClick={(e) => {
                      setSkip(skip - limit)
                    }}> Prev </button>
                  }
                  {users.length != 0 && users?.length == limit &&
                    <button className="btn btn-primary pull-right" onClick={(e) => {
                      setSkip(skip + limit)
                    }} > Next </button>
                  }
                </div>
              } */}
            </div>

          </main>
        </div>
      </body>
    </>
  )
}
const mapDispatchToProps = {
  toggleLoader
}
const mapStateToProps = (state) => {
  return {
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(ApprovedPhysios);
