import React, { useEffect, useState } from 'react';
import Topbar from '../components/Topbar';
import Leftbar from '../components/Leftbar';
import { Link } from 'react-router-dom';
import { displayError, displaySuccess, getInterests, getSpecialtyById } from '../Utils/utils';
import axios from 'axios';
import config from '../config/config';
import { connect } from 'react-redux';
import { toggleLoader } from '../actions/loader';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from 'moment';

const EditSpecialty = (props) => {
  const [name, setName] = useState('');
  const [desc, setDesc] = useState('');
  const [imageUrl, setImageUrl] = useState('');
  const [iconUrl, setIconUrl] = useState('');
  const [interests,setInterests] = useState([]);
  const [selectedValue, setSelectedValue] = useState('');

  const getKyc = async () => {
    props.toggleLoader(true)
    const data = await getSpecialtyById(props.match.params.id);
    setName(data.data?.name)
    setDesc(data.data?.description)
    setImageUrl(data?.data?.image)
    setIconUrl(data?.data?.icon)
    setSelectedValue(data?.data?.interest_id);
    props.toggleLoader(false)
  }

  useEffect(() => {
    getKyc()
  }, [])
 
  const addSpecialty = async () => {
    props.toggleLoader(true);
    let path = config.siteUrl;
    path = path + '/update-specialty/' + props?.match?.params?.id;
    var token = localStorage.getItem('token');

    const data = {
      'name': name,
      'description': desc,
      'image': imageUrl,
      'icon': iconUrl,
      'interestId': selectedValue,
    }
    axios.post(path, data, {
      headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` }
    })
      .then(function (response) {
        props.toggleLoader(false);
        displaySuccess(response?.data?.message);
        setTimeout(() => window.location.href = "/specialties", 3000)
      })
      .catch(function (error) {
        props.toggleLoader(false);
        displayError(error);
      })
  }

  const uploadImage = async (e, type) => {
    props.toggleLoader(true);
    if (!e.target.files[0]) {
      displayError('Please select a file')
    }
    else {
      let path = config.siteUrl;
      path = path + '/image-upload';
      var token = localStorage.getItem('token');

      const data = new FormData();
      data.append('file', e.target.files[0]);
      axios.post(path, data, {
        headers: { 'Content-Type': 'multipart/form-data', 'Accept': 'multipart/form-data', Authorization: `Bearer ${token}` }
      })
        .then(function (response) {
          props.toggleLoader(false);
          // displaySuccess(response?.data?.message);
          if (type == 'icon') {
            setIconUrl(response?.data?.data)
          }
          else {
            setImageUrl(response?.data?.data)
          }
        })
        .catch(function (error) {
          props.toggleLoader(false);
          displayError(error);
        })
    }
  }


 const getList = async () => {
    props.toggleLoader(true);
    var getInterests_var = await getInterests();
    setInterests(getInterests_var.data);
    props.toggleLoader(false);
  }
  useEffect(() => {
    getList()
  }, [])


  return (
    <>
      <body class="g-sidenav-show  bg-gray-100">
        <div id="wrapper">
          <Leftbar />
          <main className="main-content border-radius-lg">
            <Topbar />
            <div>
              <div className="header bg-primary pb-6">
                <div className="container-fluid">
                  <div className="header-body">
                    <div className="row align-items-center py-4">
                      <div className="col-lg-6 col-7">
                        <nav aria-label="breadcrumb" className="d-none d-md-inline-block ml-md-4">
                          <ol className="breadcrumb breadcrumb-links breadcrumb-dark">
                            <li className="breadcrumb-item"><Link to="/dashboard"><i className="fas fa-home" /></Link ></li>
                            <li className="breadcrumb-item"><Link to="/specialties">Specialty </Link ></li>
                            <li className="breadcrumb-item active" aria-current="page">Edit Specialty</li>
                          </ol>
                        </nav>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="container-fluid mt--6">
                <div className="row">
                  <div className="col">
                    <div className="card mb-4">
                      <div className="card-header">
                        <h3 className="mb-0">Edit Specialty</h3>
                      </div>
                      <div className="card-body">
                        <div className="row">
                          <div className="col-md-4">
                            <div className="form-group">
                              <label className="form-control-label" >Name</label>
                              <input type="text" className="form-control" placeholder="Name"
                                onChange={e => setName(e.target.value)} value={name}
                              />
                            </div>
                          </div>
                          <div className="col-md-4">
                            <div className="form-group">
                              <label className="form-control-label">Description</label>
                              <input type="text" className="form-control" placeholder="Description"
                                onChange={e => setDesc(e.target.value)} value={desc}
                              />
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="form-group">
                              <label className="form-control-label" >Image</label>
                              <input type="file" accept=".jpg,.jpeg,.png" className="form-control"
                                onChange={e => { uploadImage(e, 'image') }} />
                            </div>
                          </div>
                          <div className="col-md-4">
                            <div className="doc_type">Uploaded Image</div>
                            <img style={{ width: 'auto', height: 200 }} src={imageUrl} />
                          </div>
                          <div className="col-md-6">
                            <div className="form-group">
                              <label className="form-control-label" >Icon</label>
                              <input type="file" accept=".jpg,.jpeg,.png" className="form-control"
                                onChange={e => { uploadImage(e, 'icon') }} />
                            </div>
                          </div>
                          <div className="col-md-4">
                            <div className="doc_type">Uploaded Icon</div>
                            <img style={{ width: 'auto', height: 200 }} src={iconUrl} />
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="form-group">
                            <label className="form-control-label" >Interest</label>
                            <div>
                              <select className="form-select select-dropdown" aria-label="Default select example"
                                value={selectedValue} onChange={(e)=>setSelectedValue(e.target.value)}
                                >
                                <option value="" >Select Interest</option>
                                  {interests && interests?.map((item,ind)=>{
                                  return <option value={item?._id} >{item?.name}</option>
                                  })}
                              </select>
                            </div>
                          </div>
                        </div>
                        <button className="btn btn-primary"
                          onClick={addSpecialty}
                        > <i className="far fa-paper-plane" /> Submit </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </main>
        </div>
      </body>
    </>
  );
}
const mapDispatchToProps = {
  toggleLoader
}
const mapStateToProps = (state) => {
  return {
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(EditSpecialty);



