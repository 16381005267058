import React, { useEffect, useState } from 'react';
import Topbar from '../components/Topbar';
import Leftbar from '../components/Leftbar';
import { Link } from 'react-router-dom';
import { displayError, displaySuccess, getBookings, sessionList } from '../Utils/utils';
import { toggleLoader } from '../actions/loader';
import { connect } from 'react-redux';
import DatePicker from 'react-datepicker';
import config from '../config/config';
import axios from 'axios';
import moment from 'moment';

const BookingList = (props) => {
  const [users, setUsers] = useState([]);
  const [limit, setLimit] = useState(10);
  const [skip, setSkip] = useState(0);
  const [bookingIds, setBookingIds] = useState([]);
  const queryParams = new URLSearchParams(window.location.search);
  const userId = queryParams.get('user_id') || '';
  const physioId = queryParams.get('physio_id') || '';
  const [bookingStatus, setBookingStatus] = useState('all')
  const [fromDate, setFromDate] = useState(new Date());
  const [toDate, setToDate] = useState(new Date());

  const getList = async () => {
    props.toggleLoader(true);
    var getUsers_var = await getBookings(limit, skip, userId, physioId, bookingStatus, moment(fromDate).format('YYYY-MM-DD'), moment(toDate).format('YYYY-MM-DD'));
    setUsers(getUsers_var.data);
    console.log(getUsers_var.data);
    props.toggleLoader(false);
  }

  const clearFilter = async () => {
    setBookingStatus('all')
    setFromDate(new Date())
    setToDate(new Date())
  }


  useEffect(() => {
    getList()
  }, [skip, bookingStatus, fromDate, toDate])

  const handleButtonClick = ( id ) => {
    // URL to open in the new tab
    const url = `${window.location.origin}/booking-sessions/${id}`

    // Open the URL in a new tab
    window.open(url, '_blank');
  };

  return (
    <>
      <body class="g-sidenav-show  bg-gray-100">
        <div id="wrapper">
          <Leftbar />
          <main className="main-content border-radius-lg">
            <Topbar />

            <div className="header bg-primary pb-6">
              <div className="container-fluid">
                <div className="header-body">
                  <div className="row align-items-center py-4">
                    <div className="col-lg-6 col-7">
                      <nav aria-label="breadcrumb" className="d-none d-md-inline-block ml-md-4">
                        <ol className="breadcrumb breadcrumb-links breadcrumb-dark">
                          <li className="breadcrumb-item"><Link to="/dashboard"><i className="fas fa-home" /></Link></li>
                          <li className="breadcrumb-item"><a href="#">Bookings</a></li>
                        </ol>
                      </nav>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="container-fluid mt--6">
              <div className="row">
                <div className="col">
                  <div className="card">
                    <div className="card-header border-0">
                      <div className="row">
                        <div className="col-9">
                          <h3 className="mb-0">Bookings Listing </h3>
                        </div>
                      </div>
                      <div className='row mt-4'>
                        <div className="col-md-3">
                          <div className="form-group">
                            <label className="form-control-label" >Booking Status</label>
                            <select className="form-control"
                              onChange={e => setBookingStatus(e.target.value)}
                              value={bookingStatus}
                            >
                              <option selected value="all">All</option>
                              <option value="awaiting">Awaiting</option>
                              <option value="accepted">Accepted</option>
                              <option value="rejected">Rejected</option>
                              <option value="cancelled">Cancelled</option>
                              <option value="cancellation requested">Cancellation Requested</option>
                              <option Value="cancellation approved">Cancellation Approved</option>
                              <option value="completed">Completed</option>
                            </select>
                          </div>
                        </div>
                        <div className="col-md-3">
                          <div className="form-group">
                            <label className="form-control-label" >From Date</label><br />
                            <DatePicker
                              selected={fromDate}
                              dateFormat="yyyy-MM-dd"
                              onChange={(date) => setFromDate(date)}
                              maxDate={new Date()}
                              customInput={
                                <input
                                  // style={{ width: '360px' }}
                                  type="text"
                                  className="form-control"
                                  placeholder={('Date')}
                                  value={fromDate}
                                />
                              }
                            />
                          </div>
                        </div>
                        <div className="col-md-3">
                          <div className="form-group">
                            <label className="form-control-label" >To Date</label><br />
                            <DatePicker
                              selected={toDate}
                              dateFormat="yyyy-MM-dd"
                              onChange={(date) => setToDate(date)}
                              minDate={fromDate}
                              maxDate={new Date()}
                              customInput={
                                <input
                                  // style={{ width: '360px' }}
                                  type="text"
                                  className="form-control"
                                  placeholder={('Date')}
                                  value={toDate}
                                />
                              }
                            />
                          </div>
                        </div>
                        <div className="col-md-3">
                          <label className="form-control-label" ></label><br />
                          <button className="btn btn-primary pull-right mt-2" onClick={clearFilter} >Clear</button>
                        </div>
                      </div>
                    </div>
                    <div className="table-responsive">
                      <table className="table align-items-center table-flush">
                        <thead className="thead-light">
                          <tr>
                            <th>User</th>
                            <th>Service Provider</th>
                            <th>Booking Status</th>
                            <th>Payment Status</th>
                            <th>Booking Date</th>
                            <th>Sessions</th>
                          </tr>
                        </thead>
                        <tbody>
                          {users?.map((user) => (
                            <tr>
                              <td className="table-user">
                                <b>{user.user.first_name} {user.user.last_name}</b>
                              </td>
                              <td>
                                <b>{user.physio.first_name} {user.physio.last_name}</b>
                              </td>
                              <td>
                                <span className="text-muted">
                                  {user.booking?.status}
                                </span>
                              </td>
                              <td>
                                <span className="text-muted">{user.booking?.payment_status} </span>
                              </td>
                              <td>
                                {user.booking?.createdAt !== undefined ?
                                  <span className="text-muted">{moment(user.booking?.createdAt).format('ddd, DD MMM YYYY')} </span>
                                  :
                                  <span className="text-muted">{moment(user.booking?.created_at).format('ddd, DD MMM YYYY')} </span>}
                                {/* <span className="text-muted">{moment(user.booking?.createdAt).format('DD-MM-YYYY')} </span> */}
                              </td>
                              <td>
                                {/* <Link to={`/booking-sessions/${user?.booking?._id}`}> */}
                                <button className='btn btn-primary pull-right ml-2'
                                  onClick={() => handleButtonClick(user?.booking?._id)}
                                  style={{ width: '140px' }}>
                                  View Sessions
                                </button>
                                {/* </Link> */}

                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>

              {users && users.length > 0 &&
                <div style={{ display: 'flex', justifyContent: 'end' }}>
                  {skip != 0 &&
                    <button className="btn btn-primary mr-4" onClick={(e) => {
                      setSkip(skip - limit)
                    }}> Prev </button>
                  }
                  {users.length != 0 && users?.length == limit &&
                    <button className="btn btn-primary pull-right" onClick={(e) => {
                      setSkip(skip + limit)
                    }} > Next </button>
                  }
                </div>
              }
            </div>
          </main>
        </div>
      </body>
    </>
  )
}
const mapDispatchToProps = {
  toggleLoader
}
const mapStateToProps = (state) => {
  return {
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(BookingList);
