import React, { useState } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import axios from "axios";
import apiUrl from "../config/apiPath";
import config from "../config/config";
import moment from 'moment';

export const displayErrorString = (error) => {
  toast.configure({
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: false,
    progress: false,
  })
  toast.error(error);
}
export const displayError = (error) => {
  toast.configure({
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: false,
    progress: false,
  })
  if (error.response.status === 422) {
    // var row = [];
    // Object.keys(error.response.data.errors).forEach((key, i) => {
    //   row.push(error.response.data.errors[key][0]);
    // })
    // const Msg = ({ closeToast, toastProps }) => (
    //   row.map((char, i) => {
    //     return (
    //       <li>
    //         {char}
    //       </li>
    //     );
    //   })
    // )
    toast.error(error.response.data.message);
  } else if (error.response.status === 400 || error.response.status === 401) {
    toast.error(error.response.data.message);
  } else {
    toast.error(error.message);
  }
}
export const displaySuccess = (success) => {
  toast.configure({
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: false,
    progress: false,
  })
  toast.success(success);
}
export const getUsers = async (limit = '', skip = '', search = '', sort = "") => {
  let path = config.siteUrl;
  var token = localStorage.getItem('token');
  var response_final = await axios.get(path + "/get-users?limit=" + limit + "&skip=" + skip + "&search=" + search + "&sort=" + sort, {
    headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` }
  })
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      return [];
    })
  return response_final;
}
export const getPhyios = async (limit = '', skip = '') => {
  let path = config.siteUrl;
  var token = localStorage.getItem('token');
  var response_final = await axios.get(path + "/get-physios?limit=" + limit + "&skip=" + skip, {
    headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` }
  })
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      return [];
    })
  return response_final;
}
export const getPhyioKyc = async (id) => {
  let path = config.siteUrl;
  var token = localStorage.getItem('token');
  var response_final = await axios.get(path + "/view-physio-kyc/" + id, {
    headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` }
  })
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      return [];
    })
  return response_final;
}
export const getCoupons = async (limit = '', skip = '') => {
  let path = config.siteUrl;
  var token = localStorage.getItem('token');
  var response_final = await axios.get(path + "/get-coupons?limit=" + limit + "&skip=" + skip, {
    headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` }
  })
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      return [];
    })
  return response_final;
}
export const getCouponById = async (id) => {
  let path = config.siteUrl;
  var token = localStorage.getItem('token');
  var response_final = await axios.get(path + "/coupon-details/" + id, {
    headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` }
  })
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      return [];
    })
  return response_final;
}
export const exportPhysio = async (data) => {
  let path = config.siteUrl;
  var token = localStorage.getItem('token');
  var response_final = await axios.post(path + "/export-verified-physios", data, {
    headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` }
  })
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      return [];
    })
  return response_final;
}
export const exportUnverifiedPhysio = async (data) => {
  let path = config.siteUrl;
  var token = localStorage.getItem('token');
  var response_final = await axios.post(path + "/export-unverified-physios", data, {
    headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` }
  })
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      return [];
    })
  return response_final;
}
export const exportUser = async (data) => {
  let path = config.siteUrl;
  var token = localStorage.getItem('token');
  var response_final = await axios.post(path + "/export-users", data, {
    headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` }
  })
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      return [];
    })
  return response_final;
}
export const dashCounts = async (data) => {
  let path = config.siteUrl;
  var token = localStorage.getItem('token');
  var response_final = await axios.get(path + "/dashboard", {
    headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` }
  })
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      return [];
    })
  return response_final;
}
export const getSpecialties = async (limit = '', skip = '') => {
  let path = config.siteUrl;
  var token = localStorage.getItem('token');
  var response_final = await axios.get(path + "/get-specialties?limit=" + limit + "&skip=" + skip, {
    headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` }
  })
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      return [];
    })
  return response_final;
}
export const getInterests = async (limit = '', skip = '') => {
  let path = config.siteUrl;
  var token = localStorage.getItem('token');
  var response_final = await axios.get(path + "/get-interests?limit=" + limit + "&skip=" + skip, {
    headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` }
  })
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      return [];
    })
  return response_final;
}
export const getPredefinedMessages = async (limit = '', skip = '') => {
  let path = config.siteUrl;
  var token = localStorage.getItem('token');
  var response_final = await axios.get(path + "/predefined-message?limit=" + limit + "&skip=" + skip, {
    headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` }
  })
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      return [];
    })
  return response_final;
}
export const getSpecialtyById = async (id) => {
  let path = config.siteUrl;
  var token = localStorage.getItem('token');
  var response_final = await axios.get(path + "/specialty-details/" + id, {
    headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` }
  })
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      return [];
    })
  return response_final;
}
export const getInterestById = async (id) => {
  let path = config.siteUrl;
  var token = localStorage.getItem('token');
  var response_final = await axios.get(path + "/interest-details/" + id, {
    headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` }
  })
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      return [];
    })
  return response_final;
}
export const getPredefinedMsgById = async (id) => {
  let path = config.siteUrl;
  var token = localStorage.getItem('token');
  var response_final = await axios.get(path + "/predefined-message-details/" + id, {
    headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` }
  })
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      return [];
    })
  return response_final;
}
export const getAllTransactions = async (limit = '', skip = '') => {
  let path = config.siteUrl;
  var token = localStorage.getItem('token');
  var response_final = await axios.get(path + "/all-transactions?limit=" + limit + "&skip=" + skip, {
    headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` }
  })
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      return [];
    })
  return response_final;
}
export const getBookings = async (limit = '', skip = '', user_id = '', physio_id = '', booking_status = 'all', from_date = '', to_date = '') => {
  let path = config.siteUrl;
  var token = localStorage.getItem('token');
  var response_final = await axios.post(path + `/all-bookings`, {
    limit: limit,
    skip: skip,
    user_id: user_id,
    physio_id: physio_id,
    booking_status: booking_status,
    from_date: from_date == moment(new Date()).format('YYYY-MM-DD') ? '' : from_date,
    to_date: to_date == moment(new Date()).format('YYYY-MM-DD') ? '' : to_date
  }, {
    headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` }
  })
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      return [];
    })
  return response_final;
}
export const getCancelBooking = async (limit = '', skip = '') => {
  let path = config.siteUrl;
  var token = localStorage.getItem('token');
  var response_final = await axios.get(path + "/cancelled-bookings", {
    headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` }
  })
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      return [];
    })
  return response_final;
}
export const getRefund = async (id) => {
  let path = config.siteUrl;
  var token = localStorage.getItem('token');
  var response_final = await axios.get(path + "/refund-payment/" + id, {
    headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` }
  })
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      return [];
    })
  return response_final;
}
export const getVerifiedPhyios = async (limit = '', skip = '', search = '', banStatus = '', role = '', sort = "") => {
  const data = {
    // + limit + "&offset=" + skip + "&search=" + search
    limit: limit,
    offset: skip,
    sort: sort, //asc, desc
    search: search,
    is_banned: banStatus, //true, false, ""
    doctor_interest: role
  }
  let path = config.siteUrl;
  var token = localStorage.getItem('token');
  var response_final = await axios.post(path + "/get-verified-physios", data, {
    headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` }
  })
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      return [];
    })
  return response_final;
}
export const getUnverifiedPhyios = async (limit = '', skip = '', search = '', kycStatus = '', banStatus = '', role = '', sort = "") => {
  const data = {
    limit: limit,
    offset: skip,
    sort: sort, //asc, desc
    kyc: kycStatus,
    search: search,
    is_banned: banStatus, //true, false, ""
    doctor_interest: role
  }
  let path = config.siteUrl;
  var token = localStorage.getItem('token');
  var response_final = await axios.post(path + "/get-unverified-physios", data, {
    headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` }
  })
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      return [];
    })
  return response_final;
}
export const getUserDetail = async (id) => {
  let path = config.siteUrl;
  var token = localStorage.getItem('token');
  var response_final = await axios.get(path + "/user-details/" + id, {
    headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` }
  })
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      return [];
    })
  return response_final;
}
export const getPhysioDetail = async (id) => {
  let path = config.siteUrl;
  var token = localStorage.getItem('token');
  var response_final = await axios.get(path + "/physio-details/" + id, {
    headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` }
  })
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      return [];
    })
  return response_final;
}
export const getEscrowList = async (data) => {
  let path = config.siteUrl;
  var token = localStorage.getItem('token');
  var response_final = await axios.post(path + "/escrow-list/previous-week", data, {
    headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` }
  })
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      return [];
    })
  return response_final;
}
export const getEscrowDetail = async (id) => {
  let path = config.siteUrl;
  var token = localStorage.getItem('token');
  var response_final = await axios.get(path + "/physio-escrow-sessions/previous-week/" + id, {
    headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` }
  })
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      return [];
    })
  return response_final;
}
export const getEscrowDetailForecast = async (id) => {
  let path = config.siteUrl;
  var token = localStorage.getItem('token');
  var response_final = await axios.get(path + "/physio-escrow-sessions/" + id, {
    headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` }
  })
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      return [];
    })
  return response_final;
}

export const getEscrowForecast = async (data) => {
  let path = config.siteUrl;
  var token = localStorage.getItem('token');
  var response_final = await axios.post(path + "/escrow-list", data, {
    headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` }
  })
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      return [];
    })
  return response_final;
}

export const getInterestsDropdown = async () => {
  let path = config.siteUrl;
  var token = localStorage.getItem('token');
  var response_final = await axios.get(path + "/get-interests", {
    headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` }
  })
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      return [];
    })
  return response_final;
}

export const getReportedUsers = async (limit = '', skip = '', search = '', sort = "") => {
  let path = config.siteUrl;
  var token = localStorage.getItem('token');
  const data = {
    limit: limit,
    skip: skip,
    search: search,
    sort: sort
  }
  var response_final = await axios.post(path + "/reported-users", data, {
    headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` }
  })
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      return [];
    })
  return response_final;
}

export const getReportedPhysio = async (limit = '', skip = '', search = '', sort = "") => {
  let path = config.siteUrl;
  var token = localStorage.getItem('token');
  const data = {
    limit: limit,
    skip: skip,
    search: search,
    sort: sort
  }
  var r
  var response_final = await axios.post(path + "/reported-doctors", data, {
    headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` }
  })
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      return [];
    })
  return response_final;
}

export const reportAction = async (id) => {
  let path = config.siteUrl;
  var token = localStorage.getItem('token');
  var response_final = await axios.get(path + "/action-taken/" + id, {
    headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` }
  })
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      return [];
    })
  return response_final;
}

export const sessionList = async (id) => {
  let path = config.siteUrl;
  var token = localStorage.getItem('token');
  var response_final = await axios.get(path + `/booking-sessions/`+id, {
    headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` }
  })
    .then(function (response) {
      console.log(response,"lpl")
      return response.data;
    })
    .catch(function (error) {
      console.log(error,"perrroorrr")
      return [];
    })
  return response_final;
}