import axios from 'axios';
import React, { useEffect } from 'react';
import { useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { displayError, displaySuccess } from '../Utils/utils';
import config from '../config/config';
import { connect } from 'react-redux';
import { toggleLoader } from '../actions/loader';
import apiUrl from "../config/apiPath";

const LoginScreen = (props) => {
  const history = useHistory();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const Login = (e) => {
    e.preventDefault();
    props.toggleLoader(true);
    let path = config.siteUrl;
    path = path + '/login';
    axios.post(path, {
      email: email,
      password: password
    })
      .then(function (response) {
        props.toggleLoader(false);
        displaySuccess(response?.data?.mesaage);
        localStorage.setItem('token', response.data.data.access_token);
        setTimeout(() => window.location.href = "/dashboard");
      })
      .catch(function (error) {
        props.toggleLoader(false);
        console.log(error.response)
        displayError(error);
      })
  }

  useEffect(() => {
    if (localStorage.getItem('token') !== null) {
      window.location.href = "/dashboard"
    }
  }, [])

  return (
    <>
      <section>
        <div className="page-header section-height-75">
          <div className="container">
            <div className="row">
              <div className="col-xl-4 col-lg-5 col-md-6 d-flex flex-column mx-auto">
                <div className="card card-raised shadow-10 mt-5 mt-xl-5 mb-4">
                  <div className="card-header pb-0 text-center bg-transparent">
                    <img className="mb-3" src="/img/logo.png" alt="..." style={{ height: '58px' }} />
                    <h5 className="font-weight-bolder text-dark text-gradient">Login</h5>
                    <p className="mb-0">Enter your email and password to sign in</p>
                  </div>
                  <div className="card-body">
                    <form role="form text-left">
                      <label>Email</label>
                      <div className="mb-3">
                        <input type="email" className="form-control" placeholder="Email" aria-label="Email"
                          aria-describedby="email-addon" onChange={e => setEmail(e.target.value)} />
                      </div>
                      <label>Password</label>
                      <div className="mb-3">
                        <input type="password" className="form-control" placeholder="Password"
                          aria-label="Password" aria-describedby="password-addon" onChange={e => setPassword(e.target.value)} />
                      </div>
                      <div className="d-flex mt-4 justify-content-between">
                        <button type="button" className="btn bg-primary text-white" onClick={Login}>Sign in</button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

    </>
  );
}

const mapDispatchToProps = {
  toggleLoader
}
const mapStateToProps = (state) => {
  return {
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(LoginScreen);