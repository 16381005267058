import React, { useEffect, useState } from 'react';
import Topbar from '../components/Topbar';
import Leftbar from '../components/Leftbar';
import { Link } from 'react-router-dom';
import { displayError, displaySuccess, exportUser, getEscrowForecast, getEscrowList, getUsers } from '../Utils/utils';
import { toggleLoader } from '../actions/loader';
import { connect } from 'react-redux';
import ReactPaginate from 'react-paginate';
import { Col, Row, Modal, Button } from 'react-bootstrap';
import moment from 'moment';
import config from '../config/config';
import axios from 'axios';

const EscrowForecast = (props) => {
  const [users, setUsers] = useState([]);
  const [limit, setLimit] = useState(10);
  const [skip, setSkip] = useState(0);
  const [search, setSearch] = useState('');
  const [pageCount, setPageCount] = useState(1);
  const [total, setTotal] = useState('');
  const [show, setShow] = useState(false);
  const [txnNo, setTxnNo] = useState('');
  const [note, setNote] = useState('');
  const [escData, setEscData] = useState('');
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const getList = async (search) => {
    props.toggleLoader(true);
    const request = {
      search: search
    }
    var getUsers_var = await getEscrowForecast(request);
    setUsers(getUsers_var?.data)
    props.toggleLoader(false);
  }

  useEffect(() => {
    getList(search)
  }, [skip, search])

  const handlePageClick = async (e) => {
    props.toggleLoader(true);
    window.scroll({ top: 0, left: 0, behavior: 'smooth' });
    const selectedPage = e.selected;
    setSkip(selectedPage * limit)
    // getList(search)

    props.toggleLoader(false);
  };

  const onMakePayout = async () => {
    var token = localStorage.getItem('token');
    props.toggleLoader(true);
    let path = config.siteUrl;
    await axios.post(path + '/escrow/make-payout', {
      from_date: users?.from,
      to_date: users?.to,
      physio_id: escData?.physio?._id,
      sessions: escData?.sessions,
      amount: escData?.amount,
      txn_no: txnNo,
      note: note
    }, {
      headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
    })
      .then((response) => {
        displaySuccess(response.data.message);
        props.toggleLoader(false);
        handleClose()
        getList('')

      })
      .catch((error) => {
        displayError(error);
        props.toggleLoader(false);
        handleClose()
        getList('')
      });
  }
  return (
    <>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Make payout</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <label>Transaction Number:</label>
            <input type="text" className="form-control"
              style={{ width: '60%', marginRight: 10 }}
              placeholder="Transaction Number"
              value={txnNo}
              onChange={(e) => {

                setTxnNo(e.target.value)
              }}
            />
            <label>Note:</label>
            <textarea className="form-control" placeholder='Note' onChange={(e) => setNote(e.target.value)}></textarea>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={onMakePayout}>
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>
      <body class="g-sidenav-show  bg-gray-100">
        <div id="wrapper">
          <Leftbar />
          <main className="main-content border-radius-lg">
            <Topbar />

            <div className="header bg-primary pb-6">
              <div className="container-fluid">
                <div className="header-body">
                  <div className="row align-items-center py-4">
                    <div className="col-lg-6 col-7">
                      <nav aria-label="breadcrumb" className="d-none d-md-inline-block ml-md-4">
                        <ol className="breadcrumb breadcrumb-links breadcrumb-dark">
                          <li className="breadcrumb-item"><Link to="/dashboard"><i className="fas fa-home" /></Link></li>
                          <li className="breadcrumb-item"><a href="#">Forecast Escrow</a></li>
                        </ol>
                      </nav>
                    </div>

                  </div>
                </div>
              </div>
            </div>
            <div className="container-fluid mt--6">
              <div className="row">
                <div className="col">
                  <div className="card">
                    <div className="card-header border-0">
                      <div className="row">
                        <div className="col-6">
                          <h3 className="mb-0">Forecast Escrow ({users?.from} - {users?.to})</h3>
                        </div>
                        <div className='col-6 display-end' >
                          <input type="text" className="form-control"
                            style={{ width: '35%', marginRight: 10 }}
                            placeholder="Search"
                            value={search}
                            onChange={(e) => {
                              setSkip(0)
                              setPageCount(1)
                              setSearch(e.target.value)
                            }}
                          />
                          {/* <button className="btn btn-primary " onClick={() => exportList()}>Export</button> */}
                        </div>
                      </div>
                    </div>
                    <div className="table-responsive">
                      <table className="table align-items-center table-flush">
                        <thead className="thead-light">
                          <tr>
                            <th>Name</th>
                            <th>Email</th>
                            <th>Phone Number</th>
                            <th>Sessions</th>
                            <th>Is Paid</th>
                            <th>Amount</th>
                            <th>Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {
                            // users?.escrows.length > 0 ?
                            users !== '' && users?.escrows && users?.escrows.length > 0 && users?.escrows.map((user) => (
                              <tr>
                                <td className="table-user">
                                  <Link to={"/user-detail/" + user._id}>
                                    <b className='nameee'>{user.physio?.first_name}  {user.physio?.last_name}</b>
                                  </Link>
                                </td>
                                <td className="table-user">
                                  <b className='name2'>{user.physio?.email} </b>
                                </td>

                                <td>
                                  <span className="text-muted date">{user.physio?.phone_number} </span>
                                </td>
                                <td>
                                  <span className="text-muted date">{user.sessions} </span>
                                </td>
                                <td>
                                  <span className="text-muted date">{user.is_paid == false ? 'No' : 'Yes'} </span>
                                </td>
                                <td>
                                  <span className="text-muted date">{parseInt(user.amount)} </span>
                                </td>
                                <td>
                                  <div className='row' style={{ flexWrap: 'nowrap', gap: '10px', alignItems: 'center' }}>
                                    {/* {user.is_paid == false ?
                                    <button className="btn btn-primary pull-right ml-2" onClick={() => {
                                      handleShow()
                                      setEscData(user)
                                    }}>Make Payout</button>
                                    : null} */}
                                    <Link to={"/escrow-forecast-transaction/" + user?.physio._id}>
                                      <button className="btn btn-primary pull-right ml-2" >View Transactions</button>
                                    </Link>
                                  </div>
                                </td>
                              </tr>
                            ))
                            // :
                            //   <td colSpan={6}>
                            //   <div style={{ textAlign: 'center' }}>
                            //     No Escrow
                            //   </div>
                            // </td>

                          }
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
              {users.length > 0 &&
                <Row>
                  {/* <Col xs={4}>
                    <div className='mb-1'>
                      <FormattedMessage id="Showing" /> {users?.length} <FormattedMessage id="accountants from page" /> {current_page} <FormattedMessage id="of" /> {pageCount}
                    </div>
                  </Col> */}
                  <Col xs={12}>
                    <div className='paginate-css'>
                      <ReactPaginate
                        previousLabel="<"
                        nextLabel=">"
                        breakLabel={"..."}
                        breakClassName={"break-me"}
                        pageCount={pageCount}
                        marginPagesDisplayed={0}
                        pageRangeDisplayed={3}
                        onPageChange={handlePageClick}
                        containerClassName={"pagination"}
                        // subContainerClassName={"pages pagination"}
                        activeClassName={"active"}
                      />
                    </div>
                  </Col>
                </Row>}

              {/* {users && users.length > 0 &&
                <div style={{ display: 'flex', justifyContent: 'end' }}>
                  {skip != 0 &&
                    <button className="btn btn-primary mr-4" onClick={(e) => {
                      setSkip(skip - limit)
                    }}> Prev </button>
                  }
                  {users.length != 0 && users?.length == limit &&
                    <button className="btn btn-primary pull-right" onClick={(e) => {
                      setSkip(skip + limit)
                    }} > Next </button>
                  }
                </div>
              } */}
            </div>

          </main>
        </div>
      </body>
    </>
  )
}
const mapDispatchToProps = {
  toggleLoader
}
const mapStateToProps = (state) => {
  return {
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(EscrowForecast);
