import React, { useEffect, useState } from 'react';
import Topbar from '../components/Topbar';
import Leftbar from '../components/Leftbar';
import { Link } from 'react-router-dom';
import { displayError, displaySuccess, getBookings, getCancelBooking, getRefund } from '../Utils/utils';
import { toggleLoader } from '../actions/loader';
import { connect } from 'react-redux';
import DatePicker from 'react-datepicker';
import config from '../config/config';
import axios from 'axios';
import moment from 'moment';
import SweetAlert from 'react-bootstrap-sweetalert';

const CancelledBooking = (props) => {
  const [users, setUsers] = useState([]);
  const [limit, setLimit] = useState(10);
  const [skip, setSkip] = useState(0);
  const queryParams = new URLSearchParams(window.location.search);
  const userId = queryParams.get('user_id') || '';
  const physioId = queryParams.get('physio_id') || '';
  const [bookingStatus, setBookingStatus] = useState('all')
  const [fromDate, setFromDate] = useState(new Date());
  const [toDate, setToDate] = useState(new Date());
  const [refund, setRefund] = useState('');
  const [refundId, setRefundId] = useState('');
  const [refundAmt, setRefundAmt] = useState('');

  const getList = async () => {
    props.toggleLoader(true);
    var getUsers_var = await getCancelBooking();
    setUsers(getUsers_var.data);
    console.log(getUsers_var.data,"p-------")
    props.toggleLoader(false);
  }

  const clearFilter = async () => {
    setBookingStatus('all')
    setFromDate(new Date())
    setToDate(new Date())
  }

  useEffect(() => {
    getList()
  }, [])


  const refundUser = async () => {
    props.toggleLoader(true);
    var getUsers_var = await getRefund(refundId);
    if (getUsers_var.status == 200) {
      getList()
    }
    props.toggleLoader(false);
  }
  return (
    <>
      <body class="g-sidenav-show  bg-gray-100">
        <div id="wrapper">
          <Leftbar />
          <main className="main-content border-radius-lg">
            <Topbar />

            <div className="header bg-primary pb-6">
              <div className="container-fluid">
                <div className="header-body">
                  <div className="row align-items-center py-4">
                    <div className="col-lg-6 col-7">
                      <nav aria-label="breadcrumb" className="d-none d-md-inline-block ml-md-4">
                        <ol className="breadcrumb breadcrumb-links breadcrumb-dark">
                          <li className="breadcrumb-item"><Link to="/dashboard"><i className="fas fa-home" /></Link></li>
                          <li className="breadcrumb-item"><a href="#">Cancelled Bookings</a></li>
                        </ol>
                      </nav>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="container-fluid mt--6">
              <div className="row">
                <div className="col">
                  <div className="card">
                    <div className="card-header border-0">
                      <div className="row">
                        <div className="col-9">
                          <h3 className="mb-0">Cancelled Bookings Listing </h3>
                        </div>
                      </div>

                    </div>
                    <div className="table-responsive">
                      <table className="table align-items-center table-flush">
                        <thead className="thead-light">
                          <tr>
                            <th>User</th>
                            <th>Service Provider</th>
                            <th>Cancel Reason</th>
                            <th>Amount</th>
                            <th>Payment Status</th>
                            <th>Booking Date</th>
                            <th>Refund</th>
                          </tr>
                        </thead>
                        <tbody>
                          {users?.map((user) => (
                            <tr>
                              <td className="table-user">
                                <b>{user.user.first_name} {user.user.last_name}</b>
                              </td>
                              <td>
                                <b>{user.physio.first_name} {user.physio.last_name}</b>
                              </td>
                              <td>
                                <span className="text-muted">
                                  {user.reason}
                                </span>
                              </td>
                              <td>
                                <b>{user?.user?.currency} {user.total_fees}</b>
                              </td>
                              <td>
                                <span className="text-muted">{user.payment_status} </span>
                              </td>
                              <td>
                                <span className="text-muted">{moment(user.initial_date).format('DD-MM-YYYY')} </span>
                              </td>
                              <td>
                                <div>
                                  {!user.is_refunded ?
                                    <button className="btn btn-primary pull-right mt-2" onClick={() => {
                                      setRefund(true)
                                      setRefundId(user._id)
                                      setRefundAmt(`${user?.user?.currency} ${user.total_fees}`)
                                    }} >Refund</button>
                                    :
                                    <span className="text-muted">
                                      Refunded
                                    </span>
                                    // <button className="btn btn-primary pull-right mt-2" onClick={() => bannPhysio(user._id, true)} >Ban</button>
                                  }
                                </div>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
              {refund === true ? (
                <SweetAlert
                  warning
                  showCancel
                  confirmBtnText="Yes"
                  confirmBtnBsStyle="danger"
                  title={`Refund of ${refundAmt}`}
                  onConfirm={(e) => {
                    refundUser()
                    setRefund(false)
                  }}
                  onCancel={(e) => {
                    setRefund(false)
                    setRefundId('')
                  }}
                  focusCancelBtn
                >
                  Are you sure you want to make a refund of {refundAmt}?
                </SweetAlert>
              ) : ([]
              )}
              {users && users.length > 0 &&
                <div style={{ display: 'flex', justifyContent: 'end' }}>
                  {skip != 0 &&
                    <button className="btn btn-primary mr-4" onClick={(e) => {
                      setSkip(skip - limit)
                    }}> Prev </button>
                  }
                  {users.length != 0 && users?.length == limit &&
                    <button className="btn btn-primary pull-right" onClick={(e) => {
                      setSkip(skip + limit)
                    }} > Next </button>
                  }
                </div>
              }
            </div>
          </main>
        </div>
      </body>
    </>
  )
}
const mapDispatchToProps = {
  toggleLoader
}
const mapStateToProps = (state) => {
  return {
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(CancelledBooking);
