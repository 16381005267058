import React, { useEffect, useState } from 'react';
import Topbar from '../components/Topbar';
import Leftbar from '../components/Leftbar';
import { Link } from 'react-router-dom';
import { getPhyioKyc, displayError, displaySuccess } from '../Utils/utils';
import axios from 'axios';
import config from '../config/config';
import { connect } from 'react-redux';
import { toggleLoader } from '../actions/loader';

const ViewKyc = (props) => {
  const [kyc, setKyc] = useState({});
  const [show, setShow] = useState(false);
  const [comment, setComment] = useState('');

  const getKyc = async () => {
    props.toggleLoader(true)
    const data = await getPhyioKyc(props.match.params.id);
    setKyc(data.data)
    props.toggleLoader(false)
  }

  const approveReject = (status) => {
    props.toggleLoader(true);
    let path = config.siteUrl;
    path = path + '/verify-physio-kyc';
    var token = localStorage.getItem('token');
    var data = {};
    if (status == 'verify') {
      data = {
        user_id: props.match.params.id,
        status: status,
      }
    } else {
      data = {
        user_id: props.match.params.id,
        status: status,
        rejection_comments: comment
      }
    }
    axios.post(path, data, { headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` } }
    )
      .then(function (response) {
        props.toggleLoader(false);
        displaySuccess(response?.data?.mesaage);
        window.location.href = "/physios"
      })
      .catch(function (error) {
        props.toggleLoader(false);
        displayError(error);
      })
  }

  useEffect(() => {
    getKyc()
  }, [])

  function isPdf(url) {
    const pdfRegex = /\.pdf$/i;
    return pdfRegex.test(url);
  }

  return (
    <>
      <body class="g-sidenav-show  bg-gray-100">
        <div id="wrapper">
          <Leftbar />
          <main className="main-content border-radius-lg">
            <Topbar />
            <div>
              <div className="header bg-primary pb-6">
                <div className="container-fluid">
                  <div className="header-body">
                    <div className="row align-items-center py-4">
                      <div className="col-lg-6 col-7">
                        <nav aria-label="breadcrumb" className="d-none d-md-inline-block ml-md-4">
                          <ol className="breadcrumb breadcrumb-links breadcrumb-dark">
                            <li className="breadcrumb-item"><Link to="/dashboard"><i className="fas fa-home" /></Link ></li>
                            <li className="breadcrumb-item"><Link to="/physios">Service Providers</Link ></li>
                            <li className="breadcrumb-item active" aria-current="page">View KYC</li>
                          </ol>
                        </nav>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="container-fluid mt--6">
                <div className="row">
                  <div className="col">
                    <div className="card mb-4">
                      <div className="card-header">
                        <h3 className="mb-0">View KYC</h3>
                      </div>
                      <div className="card-body">
                        {/* <div className="row"> */}
                        <div className="col-md-4">
                          <div className="doc_type">Photo Id</div>
                          <label style={{ fontSize: 14, marginRight: 20, marginLeft: 0 }}>Document Number : {kyc?.photo_id?.document_number}</label>
                          <div>

                            <a href={kyc?.photo_id?.url} target='blank' style={{ color: '#007bff' }}>
                              {isPdf(kyc?.photo_id?.url) == true ? 'Click to open PDF' :
                                <img style={{ width: 'auto', height: 200, marginLeft: 0 }} src={kyc?.photo_id?.url} />
                              }
                            </a>
                          </div>
                        </div>

                        <div className="col-md-4 mt-4">
                          <div className="doc_type">Address Proof</div>
                          <label style={{ fontSize: 14, marginRight: 20, marginLeft: 0 }}>Document Number : {kyc?.address_proof?.document_number}</label>
                          <div>

                            <a href={kyc?.address_proof?.url} target='blank' style={{ color: '#007bff' }}>
                              {isPdf(kyc?.address_proof?.url) == true ? 'Click to open PDF' :
                                <img style={{ width: 'auto', height: 200 }} src={kyc?.address_proof?.url} />
                              }
                            </a>
                          </div>
                        </div>

                        <div className="col-md-4 mt-4">
                          <div className="doc_type">Professional Proof</div>
                          <label style={{ fontSize: 14, marginRight: 20 }}>Document Number : {kyc?.professional_proof?.document_number}</label>
                          <div>

                            <a href={kyc?.professional_proof?.url} target='blank' style={{ color: '#007bff' }}>
                              {isPdf(kyc?.professional_proof?.url) == true ? 'Click to open PDF' :
                                <img style={{ width: 'auto', height: 200 }} src={kyc?.professional_proof?.url} />
                              }
                            </a>
                          </div>
                        </div>

                        {show &&
                          <div className="col-md-6 mt-4">
                            <div className="form-group">
                              <label className="form-control-label" >Rejection Comment</label>
                              <textarea className="form-control" placeholder='Rejection Comment' onChange={(e) => setComment(e.target.value)}></textarea>
                            </div>
                          </div>
                        }

                        {/* </div> */}
                        {kyc?.physio?.kyc_status == 'rejected' ?
                          <div className='reject mt-4'>
                            Rejected
                          </div>
                          : kyc?.physio?.kyc_status == 'approved' ?
                            <div className='approve mt-4'>
                              Approved
                            </div>
                            :
                            <>
                              <button className="btn btn-primary mt-4 mr-4" onClick={() => approveReject('verify')} > Approve </button>
                              <button className="btn btn-danger mt-4" onClick={() => {
                                if (show == true) {
                                  approveReject('reject')
                                }
                                else {
                                  setShow(true)
                                }
                              }} > Reject </button>
                            </>
                        }
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </main>
        </div>
      </body>
    </>
  );
}
const mapDispatchToProps = {
  toggleLoader
}
const mapStateToProps = (state) => {
  return {
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(ViewKyc);