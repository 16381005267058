import React, { useEffect, useState } from 'react';
import Topbar from '../components/Topbar';
import Leftbar from '../components/Leftbar';
import { Link } from 'react-router-dom';
import { displayError, displaySuccess, getAllTransactions } from '../Utils/utils';
import { toggleLoader } from '../actions/loader';
import { connect } from 'react-redux';
import SweetAlert from 'react-bootstrap-sweetalert';
import config from '../config/config';
import axios from 'axios';
import moment from 'moment';

const TransactionsList = (props) => {
  const [users, setUsers] = useState([]);
  const [limit, setLimit] = useState(10);
  const [skip, setSkip] = useState(0);

  const getList = async () => {
    props.toggleLoader(true);
    var getUsers_var = await getAllTransactions(limit, skip);
    setUsers(getUsers_var.data);
    props.toggleLoader(false);
  }

  useEffect(() => {
    getList()
  }, [skip])

  return (
    <>
      <body class="g-sidenav-show  bg-gray-100">
        <div id="wrapper">
          <Leftbar />
          <main className="main-content border-radius-lg">
            <Topbar />

            <div className="header bg-primary pb-6">
              <div className="container-fluid">
                <div className="header-body">
                  <div className="row align-items-center py-4">
                    <div className="col-lg-6 col-7">
                      <nav aria-label="breadcrumb" className="d-none d-md-inline-block ml-md-4">
                        <ol className="breadcrumb breadcrumb-links breadcrumb-dark">
                          <li className="breadcrumb-item"><Link to="/dashboard"><i className="fas fa-home" /></Link></li>
                          <li className="breadcrumb-item"><a href="#">Transactions</a></li>
                        </ol>
                      </nav>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="container-fluid mt--6">
              <div className="row">
                <div className="col">
                  <div className="card">
                    <div className="card-header border-0">
                      <div className="row">
                        <div className="col-9">
                          <h3 className="mb-0">Transactions Listing </h3>
                        </div>
                      </div>
                    </div>
                    <div className="table-responsive">
                      <table className="table align-items-center table-flush">
                        <thead className="thead-light">
                          <tr>
                            <th>User Name</th>
                            <th>Amount</th>
                            <th>Coupon Amount</th>
                            <th>Total Amount</th>
                            <th>Address</th>
                            <th>Type of Service</th>
                            <th>Transaction Type</th>
                            <th>Transaction Id</th>
                            <th>Payment Status</th>
                            <th>Transaction Date</th>
                          </tr>
                        </thead>
                        <tbody>
                          {users?.map((user) => (
                            <tr>
                              <td className="table-user">
                                <b>{user.user.first_name} {user.user.last_name}</b>
                              </td>
                              <td>
                                <span className="text-muted">{user.booking?.initial_fees} </span>
                              </td>

                              <td>

                                <span className="text-muted">{user.booking?.discount_amount !== undefined ? user.booking?.discount_amount.toFixed(2) : 'N/A'} </span>
                              </td>
                              <td>
                                <span className="text-muted">{user.booking?.total_fees} </span>
                              </td>
                              <td>
                                <span className="text-muted">{user.user?.address} </span>
                              </td>
                              <td>
                                <span className="text-muted">{user.doctor_interest?.name} </span>
                              </td>
                              <td>
                                <span className="text-muted">{user.transaction?.payment_type ? user.transaction?.payment_type : 'stripe'} </span>
                              </td>
                              <td>
                                <span className="text-muted">
                                  {user.transaction?.payment_type && user.transaction?.payment_type == 'razorpay' ? user.transaction?.razorpay_payment_id : user.transaction?.payment_intent}
                                </span>
                              </td>
                              <td>
                                <span className="text-muted">{user.booking?.payment_status} </span>
                              </td>
                              <td>
                                {user.transaction?.createdAt !== undefined ?
                                  <span className="text-muted">{moment(user.transaction?.createdAt).format('DD-MM-YYYY HH:MM')} </span>
                                  :
                                  <span className="text-muted">{moment(user.transaction?.created_at).format('DD-MM-YYYY HH:MM')} </span>}
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>

              {users && users.length > 0 &&
                <div style={{ display: 'flex', justifyContent: 'end' }}>
                  {skip != 0 &&
                    <button className="btn btn-primary mr-4" onClick={(e) => {
                      setSkip(skip - limit)
                    }}> Prev </button>
                  }
                  {users.length != 0 && users?.length == limit &&
                    <button className="btn btn-primary pull-right" onClick={(e) => {
                      setSkip(skip + limit)
                    }} > Next </button>
                  }
                </div>
              }
            </div>
          </main>
        </div>
      </body>
    </>
  )
}
const mapDispatchToProps = {
  toggleLoader
}
const mapStateToProps = (state) => {
  return {
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(TransactionsList);
